import { SubscriptionPerk } from '../interfaces/subscription'

export const FREESUB_PERKS: Array<SubscriptionPerk> = [
  {
    desc: '1 domain to analyze',
    tooltip: 'You can run keyword analysis for one website.',
  },
  {
    desc: '1 suggested keyword limit per report',
    tooltip:
      'On Free plans each keyword analysis report has up to 1 specific keyword you should target and reasons why you can easily rank for them.',
  },
  {
    desc: '1 analysis report per month',
    tooltip:
      'A keyword analysis report shows you specific keywords you should target and reasons why you can easily rank for them.',
  },
]

export const FREESUB_NONPERKS: Array<SubscriptionPerk> = [
  {
    desc: 'AI Assisted Writing Tools',
    tooltip:
      'Our tools include Title Generator, Content Brief & Outline generator, Meta description generator and First draft Article Writer.',
  },
  {
    desc: 'Export to XLS, PDF',
    tooltip:
      'Export allows you to easily create a downloadable version of your keyword analysis report to share with your team.',
  },
  {
    desc: '1:1 support from our team to pick right keywords',
  },
  {
    desc: 'Academy access: Top PR & SEO outreach tactics',
  },
]

export const STARTERSUB_PERKS: Array<SubscriptionPerk> = [
  {
    desc: '3 analysis reports limit per month',
    tooltip:
      'A keyword analysis report shows you specific keywords you should target and reasons why you can easily rank for them.',
  },
  {
    desc: '3 suggested keyword limit per report',
    tooltip:
      'On Starter plan each keyword analysis report has up to 3 specific keywords you should target and reasons why you can easily rank for them.',
  },
  {
    desc: '1 domain to analyze',
    // tooltip: 'You can run keyword analysis for one website.',
  },
]

export const STARTERSUB_NONPERKS: Array<SubscriptionPerk> = [
  {
    desc: 'AI Assisted Writing Tools',
    tooltip:
      'Our tools include Title Generator, Content Brief & Outline generator, Meta description generator and First draft Article Writer.',
  },
  {
    desc: 'Export to XLS, PDF',
    tooltip:
      'Export allows you to easily create a downloadable version of your keyword analysis report to share with your team.',
  },
  {
    desc: '1:1 support from our team to pick right keywords',
  },
  {
    desc: 'Academy access: Top PR & SEO outreach tactics',
  },
]

export const SEMRUSH_BASIC_PERKS: Array<SubscriptionPerk> = [
  {
    desc: '1 domain to analyze',
    tooltip: 'You can run keyword analysis for one website.',
  },
  {
    desc: '3 keywords per report',
    tooltip:
      'On Basic plan each keyword analysis report has up to 3 specific keywords you should target and reasons why you can easily rank for them.',
  },
  {
    desc: '3 analysis reports per month',
    tooltip:
      'A keyword analysis report shows you specific keywords you should target and reasons why you can easily rank for them.',
  },
  {
    desc: '90 minutes to generate keyword report',
    tooltip: '90 minutes to generate keyword report',
  },
]

export const SEMRUSH_BASIC_NOPERKS: Array<SubscriptionPerk> = [
  {
    desc: 'Export to XLS, PDF',
    tooltip:
      'Export allows you to easily create a downloadable version of your keyword analysis report to share with your team.',
  },
  {
    desc: '1:1 support from our team to pick right keywords',
  },
  {
    desc: 'Academy access: Top PR & SEO outreach tactics',
  },
]

export const SEMRUSH_PREMIUM_PERKS: Array<SubscriptionPerk> = [
  {
    desc: '3 domains to analyze',
    tooltip: 'You can run keyword analysis for three website.',
  },
  {
    desc: 'Unlimited keyword limit per report',
    tooltip:
      'On Starter plan each keyword analysis report has up to 3 specific keywords you should target and reasons why you can easily rank for them.',
  },
  {
    desc: '3 analysis reports limit per month',
    tooltip:
      'A keyword analysis report shows you specific keywords you should target and reasons why you can easily rank for them.',
  },
  {
    desc: 'Priority queue without 90 minute wait',
    tooltip:
      'You get priority queue to generate your keyword analysis which means that you will get to see your reports much faster vs. Basic tier plan members.',
  },
  {
    desc: 'Export to XLS, PDF',
    tooltip:
      'Export allows you to easily create a downloadable version of your keyword analysis report to share with your team.',
  },
  {
    desc: '1:1 support from our team to pick right keywords',
  },
  {
    desc: 'Academy access: Top PR & SEO outreach tactics',
  },
]

export const STANDARDSUB_PERKS: Array<SubscriptionPerk> = [
  {
    desc: '10 analysis reports limit per month',
    tooltip:
      'A keyword analysis report shows you specific keywords you should target and reasons why you can easily rank for them.',
  },
  {
    desc: '6 suggested keyword limit per report',
    tooltip:
      'On Basic plans each keyword analysis report has up to 6 specific keywords you should target and reasons why you can easily rank for them.',
  },
  {
    desc: '3 domains to analyze',
    tooltip: 'You can run keyword analysis for three websites.',
  },
  {
    desc: 'AI Assisted Writing Tools',
    tooltip:
      'Our tools include Title Generator, Content Brief & Outline generator, Meta description generator and First draft Article Writer.',
  },
  {
    desc: '20 exports to XLS, PDF',
    tooltip:
      'Export allows you to easily create a downloadable version of your keyword analysis report to share with your team.',
  },
  {
    desc: '1:1 support from our team to pick right keywords',
  },
  {
    desc: 'Academy access: Top PR & SEO outreach tactics',
  },
]

export const BUSINESSSUB_PERKS: Array<SubscriptionPerk> = [
  {
    desc: '30 analysis reports limit per month',
  },
  {
    desc: '12 suggested keyword limit per report',
    tooltip:
      'On Premium plans each keyword analysis report has up to 12 specific keywords you should target and reasons why you can easily rank for them.',
  },
  {
    desc: 'Unlimited domains to analyze',
  },
  {
    desc: 'AI Assisted Writing Tools',
    tooltip:
      'Our tools include Title Generator, Content Brief & Outline generator, Meta description generator and First draft Article Writer.',
  },
  {
    desc: 'Unlimited exports to XLS, PDF',
    tooltip:
      'Export allows you to easily create a downloadable version of your keyword analysis report to share with your team.',
  },
  {
    desc: '1:1 support from our team to pick right keywords',
  },
  {
    desc: 'Academy access: Top PR & SEO outreach tactics',
  },
  {
    desc: 'One keyword strategy review call with our team per month',
    tooltip:
      'We will hop on one call with you per month and help you devise a keyword strategy to rank easier and better.',
  },
]

export const COMPARE_FREE_PAID_PERKS: Array<SubscriptionPerk> = [
  {
    desc: '10 topic analysis reports per month vs. 1 report on Free plan',
  },
  {
    desc: '6 suggested keyword limit per report vs. 1 suggested keyword limit per report on Free plan',
  },
  {
    desc: 'Up to 3 domains to analyze vs. 1 domain on Free plan',
  },
  {
    desc: 'PDF/XLS export vs. no export on Free plan',
  },
  {
    desc: '1:1 support from our team to pick the right keywords',
  },
  {
    desc: 'Academy access: Top PR & SEO outreach tactics',
  },
]

export const COMPARE_STARTER_BASIC_PERKS: Array<SubscriptionPerk> = [
  {
    desc: '10 topic analysis reports per month vs. 3 report on Starter plan',
  },
  {
    desc: '6 suggested keyword limit per report vs. 3 suggested keyword limit per report on Starter plan',
  },
  {
    desc: 'Up to 3 domains to analyze vs. 1 domain on Starter plan',
  },
  {
    desc: 'AI-Assisted Writing Tools: Content Brief, Title Generator, Article Genie & more',
  },
  {
    desc: 'PDF/XLS export',
  },
  {
    desc: '1:1 support from our team to pick the right keywords',
  },
  {
    desc: 'Academy access: Top PR & SEO outreach tactics',
  },
]

export const COMPARE_BASIC_PREMIUM_PERKS: Array<SubscriptionPerk> = [
  {
    desc: '30 topic analysis reports per month vs. 10 topic analysis reports per month',
  },
  {
    desc: '12 suggested keyword limit per report vs. 6 keyword limit per report',
  },
  {
    desc: 'Unlimited domains vs. 3 domains to analyze',
  },
  {
    desc: 'Unlimited exports to XLS, PDF vs. 20 PDF/XLSX export',
  },
  {
    desc: 'One keyword strategy and content review call with our team per month',
  },
]

export const LEFT_OVER_PREMIUM: Array<SubscriptionPerk> = [
  {
    desc: '1:1 support from our team to pick right keywords',
  },
  {
    desc: 'Academy access: Top PR & SEO outreach tactics',
  },
  {
    desc: 'One keyword strategy review call with our team per month',
  },
]

import { useState } from 'react'

type Returns = [
  state: boolean,
  handleStateOpen: () => void,
  handleStateClose: () => void
]

export default function useModalState(initalValue = false): Returns {
  const [state, setState] = useState(initalValue)
  const handleStateClose = () => setState(false)
  const handleStateOpen = () => setState(true)
  return [state, handleStateOpen, handleStateClose]
}

import {
  ExistingKeywordStatus,
  Search as PrismaSearch,
  SearchType,
} from '@prisma/client'

export interface Search {
  id: string
  keyword: string
  language: string
  country: string
  url: string
  accessToken?: string
  googleInsight: number
  mozPa: number
  mozDa: number
  spamScore: number
  createdAt: string
  updatedAt?: string
  hasBeenOpened: boolean
  flesch_reading_ease?: number
  totalLoadTime?: number
  word_count?: number
  status: SearchStatus
  searchType: ContentModes
  keywordResult: SearchResult[]
  existingKeywordResult: ExistingSearchResult[]
}

enum SearchStatus {
  PENDING = 'PENDING',
  DONE = 'DONE',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}

export interface ExistingSearchResult {
  status: ExistingKeywordStatus
  id?: string
  ctr: number
  clicks: number
  impressions: number
  position: number
  positionOld: number
  searchId: number
  keyword: string
  pageUrl: string
}

export interface SearchResult {
  id?: string
  keyword: string
  isMainKeyword: boolean
  volume: number
  competition: number
  page: number
  place: number
  titleDifference?: {
    percent: number
    missingFromTitle: number
    matches: number
  }
  organicResult: OrganicResult[]
}

export interface OrganicResult {
  link: string
  title: string
  authorityScore: number
  domainsNum: number
  mozDa: number
  mozPa: number
  spamScore: number
  relativeMozDa?: string
  date?: string | null
  tags?: string[]
  flesch_reading_ease?: number
  titleDifference?: {
    percent: number
    missingFromTitle: number
    matches: number
  }
  totalLoadTime?: number
  place?: number
  word_count?: number
}

export type newSearchData = Omit<
  PrismaSearch,
  | 'id'
  | 'status'
  | 'mozDa'
  | 'mozPa'
  | 'googleInsight'
  | 'spamScore'
  | 'accessToken'
  | 'createdAt'
  | 'updatedAt'
  | 'keywordResult'
>

export enum ContentModes {
  CREATE_NEW = 'CREATE_NEW',
  UPDATE_EXISTING = 'UPDATE_EXISTING',
}

export interface columnData {
  title: string
  subText: string
  tooltip: string
  data: string
}

export interface badgeData {
  text: string
  data: string
  badge: string
  image: string
  content: string
}

export interface TooltipData {
  title: string
  info: string
  badge: string
  todo: string
}

export interface makeSearchProps {
  searchType: ContentModes
  url: string
  keyword?: string
  language: string
  country: string
}

export default function WistiaIframe({
  src,
  width,
  height,
  autoplay = false,
}: {
  src: string
  width: number
  height: number
  autoplay?: boolean
}) {
  const source = autoplay ? `${src}?autoplay=1` : src
  return (
    <iframe
      src={source}
      frameBorder="0"
      scrolling="no"
      className="wistia_embed"
      name="wistia_embed"
      allowFullScreen
      width={width}
      height={height}
      allow=" autoplay;"
    />
  )
}

import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import styles from './brandLogo.module.scss'

const BrandLogo = ({ semrushLanding = false }) => {
  return (
    <div className={styles.container}>
      <Link href="/" passHref>
        <a className={styles.logo}>
          <Image src="/assets/logo.svg" alt="logo" width={48} height={48} />
          {semrushLanding ? (
            <span className={styles.semrushLogoText}>SERP Gap Analyzer</span>
          ) : (
            <span className={styles.logoText}>
              Topic<span>Ranker</span>
            </span>
          )}
        </a>
      </Link>
    </div>
  )
}

export default BrandLogo

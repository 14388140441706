import DialogBase from '../DialogBase/DialogBase'
import WistiaIframe from '../WistiaIframe/WistiaIframe'
import Image from 'next/image'

import MediaQuery from 'react-responsive'

import styles from './wistiaPopup.module.scss'

type Props = {
  title: string
  src: string
  isOpen: boolean
  handleClose: () => void
}

export default function VideoModal({ title, src, isOpen, handleClose }: Props) {
  const getVideoHeigthWithRatio = (width: number) => {
    return width / 1.5
  }

  return (
    <DialogBase openDialog={isOpen} handleClose={handleClose}>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <p className={styles.heading}>{title}</p>
          <button onClick={handleClose} className={styles.close_btn}>
            <MediaQuery maxWidth={500}>
              <Image
                src="/assets/black_cancel.svg"
                width={20}
                height={20}
                alt="Close modal"
                className={styles.close}
              />
            </MediaQuery>
            <MediaQuery minWidth={501}>
              <Image
                src="/assets/black_cancel.svg"
                width={30}
                height={30}
                alt="Close modal"
                className={styles.close}
              />
            </MediaQuery>
          </button>
        </div>
        <div className={styles.iframe}>
          <WistiaIframe
            autoplay
            src={src}
            width={800}
            height={getVideoHeigthWithRatio(800)}
          />
        </div>
      </div>
    </DialogBase>
  )
}

import {
  SemrushSubscriptionType,
  SubscriptionType,
} from '../interfaces/subscription'
import {
  FREESUB_PERKS,
  FREESUB_NONPERKS,
  STARTERSUB_PERKS,
  STARTERSUB_NONPERKS,
  STANDARDSUB_PERKS,
  BUSINESSSUB_PERKS,
  SEMRUSH_BASIC_PERKS,
  SEMRUSH_BASIC_NOPERKS,
  SEMRUSH_PREMIUM_PERKS,
} from '../data/subPerks'

const STRIPE_PRODUCT_IDS: any = JSON.parse(
  process.env.NEXT_PUBLIC_STRIPE_PRODUCT_IDS || '{}'
)

const godSubscription: Partial<SubscriptionType> = {
  id: 'GOD',
}

const freeSubscription: SubscriptionType = {
  id: 'FREE',
  name: 'Free',
  maxNumberOfSearches: 1,
  perks: FREESUB_PERKS,
  nonPerks: FREESUB_NONPERKS,
  prodId: {
    annual: STRIPE_PRODUCT_IDS.free,
    monthly: STRIPE_PRODUCT_IDS.free,
  },
}
const standardSubscription: SubscriptionType = {
  id: 'BASIC',
  name: 'Basic',
  maxNumberOfSearches: 10,
  perks: STANDARDSUB_PERKS,
  prodId: {
    annual: STRIPE_PRODUCT_IDS.basicAnnual,
    monthly: STRIPE_PRODUCT_IDS.basicMonthly,
  },
}

const semrushBasicSubscription: SemrushSubscriptionType = {
  id: 'SEMRUSH_BASIC',
  name: 'Basic',
  perks: SEMRUSH_BASIC_PERKS,
  nonPerks: SEMRUSH_BASIC_NOPERKS,
}

const semrushPremiumSubscription: SemrushSubscriptionType = {
  id: 'SEMRUSH_PREMIUM',
  name: 'Premium',
  perks: SEMRUSH_PREMIUM_PERKS,
}

const starterSubscription: SubscriptionType = {
  id: 'STARTER',
  name: 'Starter',
  maxNumberOfSearches: 3,
  perks: STARTERSUB_PERKS,
  nonPerks: STARTERSUB_NONPERKS,
  prodId: {
    annual: STRIPE_PRODUCT_IDS.starterAnnual,
    monthly: STRIPE_PRODUCT_IDS.starterMonthly,
  },
}
const businessSubscription: SubscriptionType = {
  id: 'PREMIUM',
  name: 'Premium',
  maxNumberOfSearches: 30,
  perks: BUSINESSSUB_PERKS,
  prodId: {
    annual: STRIPE_PRODUCT_IDS.premiumAnnual,
    monthly: STRIPE_PRODUCT_IDS.premiumMonthly,
  },
}

export const SUBSCRIPTIONS = [
  starterSubscription,
  standardSubscription,
  businessSubscription,
]

export const SEMRUSH_SUBSCRIPTIONS = [
  semrushBasicSubscription,
  semrushPremiumSubscription,
]

export const SUBSCRIPTION_TYPES = {
  GOD: godSubscription,
  FREE: freeSubscription,
  BASIC: standardSubscription,
  STARTER: starterSubscription,
  PREMIUM: businessSubscription,
  SEMRUSH_BASIC: semrushBasicSubscription,
  SEMRUSH_PREMIUM: semrushPremiumSubscription,
}

//abbreviations from https://tetran.ru/SiteContentEn/Details/13
export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
]

export const SWITCH_TYPES = [
  'Free to Basic',
  'Free to Premium',
  'Free to Starter',
  'Basic to Free',
  'Basic to Premium',
  'Basic to Starter',
  'Premium to Free',
  'Premium to Basic',
  'Premium to Starter',
] as const

export const PLAN_ITEMS = [
  { id: 'plan_free', value: 'Free' },
  { id: 'plan_starter', value: 'Starter' },
  { id: 'plan_basic', value: 'Basic' },
  { id: 'plan_premium', value: 'Premium' },
]

export const SOURCE_ITEMS = [
  { id: 'Youtube', value: 'YouTube' },
  { id: 'Twitter', value: 'Twitter' },
  { id: 'Google', value: 'Google' },
  { id: 'Newsletter', value: 'Newsletter' },
  { id: 'Blog', value: 'Blog' },
  { id: 'Other', value: 'Other' },
]

export const SWITCH_TYPES_ITEMS = [
  { id: 'Free to Basic', value: 'Free to Basic' },
  { id: 'Free to Premium', value: 'Free to Premium' },
  { id: 'Basic to Free', value: 'Basic to Free' },
  { id: 'Basic to Premium', value: 'Basic to Premium' },
  { id: 'Premium to Free', value: 'Premium to Free' },
  { id: 'Premium to Basic', value: 'Premium to Basic' },
]

export const PERSONA_ITEMS = [
  { id: 'In-house', value: 'In-House Marketer' },
  { id: 'Agency', value: 'Consultant at an Agency' },
  { id: 'Consultant', value: 'Solo Consultant' },
  { id: 'Founder', value: 'Founder' },
  { id: 'Other', value: 'Other' },
]

export const SEMRUSH_VIDEO_URL =
  'https://www.loom.com/embed/71ed5ee52a7249baab83b466588541f5'
export const WELCOME_VIDEO_URL =
  'https://www.loom.com/embed/486be85c1a2941a58cb4ed8d669388c1'
export const DEMO_VIDEO_URL =
  'https://www.loom.com/embed/486be85c1a2941a58cb4ed8d669388c1?sid=93ada87a-9071-4ada-b42b-a256cf0f88d0'

import { Dialog, Backdrop, Fade } from '@mui/material'
import { Breakpoint } from '@mui/system'
import { ReactNode } from 'react'
import styles from './dialogBase.module.scss'

type Props = {
  children: ReactNode
  size?: string
  openDialog: boolean
  handleClose?: () => void
}
export default function DialogBase({
  children,
  size = 'md',
  openDialog,
  handleClose,
}: Props) {
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={size as Breakpoint}
      PaperProps={{
        style: {
          borderRadius: '15px',
        },
      }}
      classes={{ root: styles.root }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 700,
      }}
    >
      <Fade in={openDialog} timeout={500}>
        <div>{children}</div>
      </Fade>
    </Dialog>
  )
}

import classNames from 'classnames'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useModalState from 'hooks/useModalState'
import { useSession } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import WistiaPopup from '../WistiaPopup/WistiaPopup'
import { DEMO_VIDEO_URL } from 'utils/consts'

import styles from './footer.module.scss'
import BrandLogo from '@components/BrandLogo'

type FooterProps = {
  landing?: boolean
  semrushLanding?: boolean
  setSelectedFeature?: (index: number) => void
  isSignup?: boolean
  hasWave?: boolean
  hasShapes?: boolean
}

const works = [
  { text: 'Low Hanging Fruit' },
  { text: 'Unlocking SERP Weaknesses' },
  { text: 'Empowering Your Writers' },
  { text: 'AI-Generated Title Suggestions' },
  { text: 'AI-Assisted Content Outlines' },
  { text: 'AI-Powered Blog Posts' },
  { text: 'AI-Enhanced Meta Descriptions' },
]

const topics = [
  { text: 'About', link: '/about' },
  { text: 'Blog', link: 'https://blog.topicranker.com/' },
  { text: 'Affiliate Program', link: 'https://topicranker.promotekit.com/' },
  { text: 'Testimonials', link: '/#testimonials' },
  { text: 'Contact Us', link: '/' },
]

const Footer = ({
  landing,
  setSelectedFeature,
  hasWave = false,
  semrushLanding = false,
  hasShapes = false,
}: FooterProps) => {
  const { status } = useSession()
  const linkStyles = landing ? styles.orange : styles.blue

  const [isWistiaOpen, openWistia, closeWistia] = useModalState(false)

  const router = useRouter()

  const openContactUsChat = () => {
    const { purechatApi } = window as any
    purechatApi.set('chatbox.expanded', true)
  }

  const navigate = (link: any) => {
    if (link.text === 'Contact Us') {
      openContactUsChat()
      return
    }

    if (link.text === 'Pricing' && status === 'authenticated') {
      router.push('/settings/subscription')
      return
    }

    window.scrollTo(0, 0)
    router.push(link.link)
  }

  const learn = [
    {
      text: 'Watch TopicRanker Walkthrough',
      action: openWistia,
    },
    {
      text: '4 Steps To Find Keywords with SERP Weak Spots (Tutorial)',
      link: 'https://blog.topicranker.com/how-to-use-topic-ranker/',
    },
    {
      text: 'Why Easy-To-Rank Keywords Must Have Weak Spots',
      link: 'https://blog.topicranker.com/which-keywords-are-best-to-target/',
    },
    {
      text: 'Why Most SEO Target Keyword Suggestions are Not Accurate',
      link: 'https://blog.topicranker.com/seo-target-keywords/',
    },
  ]

  return (
    <>
      <WistiaPopup
        title="TopicRanker Walkthrough"
        isOpen={isWistiaOpen}
        handleClose={closeWistia}
        src={DEMO_VIDEO_URL}
      />
      <footer
        className={classNames(styles.footer, {
          [styles.background]: landing,
          // [styles.smallFooter]: !landing,
        })}
      >
        {hasWave && (
          <>
            {/* eslint-disable-next-line @next/next/no-img-element*/}
            <img
              src={'/assets/tr-wave-bottom.svg'}
              className={styles.footerWave}
              alt="Wave shape"
            />
          </>
        )}

        {hasShapes && (
          <>
            {/* eslint-disable-next-line @next/next/no-img-element*/}
            <img
              src={'/assets/tr-shapes.svg'}
              className={styles.footerShapes}
              alt="Geometric shapes"
            />
          </>
        )}

        <div className={styles.footerContent}>
          <section data-name="brand">
            <BrandLogo />
            {semrushLanding && (
              <span className={styles.semrushText}>
                on
                <img
                  src={'/assets/landing/semrush_logo_white.png'}
                  className={styles.semrushLogo}
                  alt="Semrush"
                />
                app center
              </span>
            )}

            <div className={styles.footerBrandMessage}>
              <div>
                Made with&nbsp;
                <FontAwesomeIcon color="red" icon={faHeart} />
                &nbsp;by&nbsp;
              </div>
              <div>
                <Link href="https://www.criminallyprolific.com/" passHref>
                  <a className={styles.link}>CriminallyProlific.com</a>
                </Link>
              </div>
            </div>

            <div className={styles.footerFineprint}>
              <div>
                <Link href="/about/terms-of-service">
                  <a className={styles.link}>Terms of Service</a>
                </Link>
              </div>
              <div>
                <Link href="/about/privacy-policy">
                  <a className={styles.link}>Privacy Policy</a>
                </Link>
              </div>
            </div>
          </section>

          <section data-name="learn">
            <h4 className={styles.title}>Learn</h4>
            <div className={styles.column}>
              {learn.map((link, index) =>
                link.action ? (
                  <button
                    onClick={link.action}
                    key={index}
                    className={`${styles.link} ${styles.button}`}
                  >
                    {link.text}
                  </button>
                ) : (
                  <Link href={link.link} key={index}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.link}
                    >
                      {link.text}
                    </a>
                  </Link>
                )
              )}
            </div>
          </section>

          <section data-name="help" className={styles.column}>
            <h4 className={styles.title}>Need help?</h4>
            <div>
              <a
                onClick={() => openContactUsChat()}
                className={classNames(styles.link, styles.notCapitalized)}
              >
                Click here to contact us
              </a>
            </div>
          </section>

          <section data-name="how-it-works">
            <h4 className={styles.title}>How it works</h4>
            <div className={styles.column}>
              {works.map((link, index) => (
                <div
                  onClick={() =>
                    setSelectedFeature ? setSelectedFeature(index) : null
                  }
                  key={`HowItWorks_Link_${index}`}
                  role="button"
                >
                  <Link href={`/?tab=${index}#features`} key={index}>
                    <a className={styles.link}>{link.text}</a>
                  </Link>
                </div>
              ))}
            </div>
          </section>

          <section data-name="topics">
            <h4 className={styles.title}>Topic Ranker</h4>
            <div className={styles.column}>
              {topics.map((link, index) => (
                <div key={index}>
                  <a onClick={() => navigate(link)} className={styles.link}>
                    {link.text}
                  </a>
                </div>
              ))}
            </div>
          </section>
        </div>

        <div className={styles.footerBase}>
          <span>
            &copy; {new Date().getFullYear()} TopicRanker Inc. All rights
            Reserved
          </span>
        </div>
      </footer>
    </>
  )
}

export default Footer

import { atom } from 'recoil'
import { Search, OrganicResult, ContentModes } from '../interfaces/search'
import { Profile, SearchData } from '../interfaces/profile'
import { SubscriptionSignUpData } from '../interfaces/subscription'
import { SubscriptionType } from '@prisma/client'

export const profileState = atom<Profile | null>({
  key: 'profileState',
  default: null,
})

export const searchBarDataState = atom<SearchData>({
  key: 'searchBarDataState',
  default: {
    userUid: '',
    myURL: '',
    keyword: '',
    searchType: ContentModes.CREATE_NEW,
    language: '',
    country: '',
    automaticallyStartSearch: false,
  },
})

export const resultState = atom<Search | null>({
  key: 'resultState',
  default: null,
})

export const searchNumberState = atom<number>({
  key: 'searchNumberState',
  default: 0,
})

export const loaderStatus = atom<boolean>({
  key: 'loaderStatus',
  default: false,
})

export const myDatasState = atom<OrganicResult | null>({
  key: 'myDatasState',
  default: null,
})

export const currentSearchState = atom<Search | null>({
  key: 'currentSearchState',
  default: null,
})

export const subscriptionSignUpState = atom<SubscriptionSignUpData>({
  key: 'subscriptionSignUpState',
  default: {
    priceId: '',
    customerStripeId: '',
    type: SubscriptionType.FREE,
    annual: false,
    subscriptionId: '',
  },
})

export const calendarPopupState = atom({
  key: 'calendarPopupState',
  default: false,
})
